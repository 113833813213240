/* Datepicker */

.datepicker {
	border-color: $border-color;
	border-radius: $border-radius;
	@include font-face($TYPE-1, 400);
	color: $body-accent-text-color;

	&.datepicker-dropdown {
		position: absolute;
		padding: 10px;
		border: none;
		box-shadow: $datepicker-dropdown-shadow;
		background: $body-accent-color;

		&:before {
			content: "";
			border: none;
			background: $body-accent-color;
			box-shadow: none;
		}

		&.datepicker-orient-top {
			&:after {
				border-top-color: $body-accent-color;
			}
		}

		&.datepicker-orient-bottom {
			&:after {
				border-bottom-color: $body-accent-color;
			}
		}

		table {
			thead {
				tr {
					th {

						&.prev,
						&.datepicker-switch,
						&.next {
							height: 65px;

							&:hover {
								background: $body-accent-color;
							}
						}



						&.datepicker-switch {
							@include font-face($TYPE-1, 600);
							@include ms-respond(font-size, 0);
							letter-spacing: 1px;
							color: $text-gray;
						}

						&.next,
						&.prev {
							color: $text-muted;
							@include ms-respond(font-size, 3);
						}

						&.dow {
							color: theme-color(primary);
							@include font-face($TYPE-1, 700);
							@include ms-respond(font-size, -1);
							letter-spacing: $letter-spacing;
							text-transform: uppercase;
						}
					}
				}
			}

			tbody {
				tr {
					td.day {
						border-radius: 25px;
						height: 40px;
						width: 40px;
						@include font-face($TYPE-1, 500);
						@include ms-respond(font-size, -2);
						letter-spacing: $letter-spacing;
						color: $text-gray;
						text-shadow: none;
						transition: $transition-base;
						transition-property: background, color, font;

						&:hover {
							background: transparent;
						}

						&.active,
						&.today {
							@include font-face($TYPE-1, 700);
							color: $white;
							box-shadow: $component-box-shadow;
						}

						&.active {
							background: theme-color(danger);
						}

						&.today {
							background: theme-color(success);
						}

						&.new {
							color: lighten($body-text-color, 30%);
						}

						&.old {
							color: $text-muted;
						}
					}
				}
			}
		}
	}
}