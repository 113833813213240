/* Jquery Toast */

.jq-toast-wrap {
    &.bottom-left {
        left: $body-margin-x;
        bottom: 20px;
        top: unset !important;
    }

    &.mid-center {
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.bottom-center {
        left: 0;
        top: unset !important;
        bottom: 20px;
    }

    &.bottom-right {
        right: $body-margin-x;
        top: unset !important;
        bottom: 20px;
    }

    .jq-toast-single {
        color: $body-accent-color;
        @include font-face($TYPE-1, 500);
        box-shadow: $component-box-shadow;
        background: theme-color(primary);

        .jq-toast-heading {
            @include ms-respond(font-size, -1);
            @include font-face($TYPE-1, 500);
        }

        &.jq-icon-success {
            background: theme-color(success);
        }

        &.jq-icon-error {
            background: theme-color(danger);
        }

        &.jq-icon-warning {
            background: theme-color(warning);
        }
    }
}