/* Switchery */

.switchery {
    height: 20px;
    width: 35px;
    border: none;
    background: rgba($body-accent-color, 0.8);

    small {
        width: 20px;
        height: 20px;
        box-shadow: $component-box-shadow;
    }

    &.switch-primary {
        small {
            background: theme-color(primary);
        }
    }

    &.switch-success {
        small {
            background: theme-color(success);
        }
    }

    &.switch-warning {
        small {
            background: theme-color(warning);
        }
    }

    &.switch-info {
        small {
            background: theme-color(info);
        }
    }

    &.switch-danger {
        small {
            background: theme-color(danger);
        }
    }
}