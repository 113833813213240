 /* TinyMCE */

 .mce-tinymce {
     box-shadow: none !important;
 }

 .mce-panel {
     border: $border-width $border-style $border-color !important;
 }

 .mce-menubar {
     border: $border-width $border-style $border-color;
 }

 .mce-toolbar-grp {
     border: none !important;
 }