/* Badges */

@mixin badge-inverse-variation($color) {
  background: rgba($color, 0.1);
  color: $color;
}

@each $color,
$value in $theme-colors {
  .badge-inverse-#{$color} {
    @include badge-inverse-variation($value);
  }
}

@mixin badge-outline-variation($color) {
  border: 2px $border-style $color;
  color: $color;
}

@each $color,
$value in $theme-colors {
  .badge-outline-#{$color} {
    @include badge-outline-variation($value);
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @include font-face($TYPE-1, 700);
  letter-spacing: $letter-spacing;

  &:not([class*="badge-inverse"]):not(.component-flat) {
    box-shadow: $component-box-shadow;
  }

  .status-indicator {
    margin-right: 5px;
    height: 6px;
    width: 6px;
    min-width: 6px;
  }

  &.badge-pill {
    line-height: 9px;
    border-radius: 10rem;
  }

  &.icon-badge {
    height: 30px;
    width: 30px;
    border-radius: 4px;
    @include font-size(20);
  }

  &.badge-rounded {
    border-radius: 50px;
  }

  &.badge-fw {
    width: 80px;
  }

  &.badge-left {
    margin-right: 10px;
  }

  &.badge-right {
    margin-left: 10px;
  }

  &.badge-transparent {
    background: transparent;
    border: $border-width $border-style $border-color;
    color: $body-text-inverse-color;
  }

  &.badge-outline-light {
    background: transparent;
    border: 2px $border-style theme-color(light);
  }

  &.badge-danger {
    color: $body-text-inverse-color;
  }
}