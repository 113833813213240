/* Alerts */

.alert {
    border: none;
    @include ms-respond(font-size, -1);
    @include font-face($TYPE-2, 400);

    .alert-heading {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .btn-dismmiss {
        margin-top: 20px;
    }

    &.dismissible-alert {
        position: relative;
        padding-right: 40px;

        .alert-close {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0;
            padding: 10px;
            font-size: 18px;
            display: flex;
            cursor: pointer;
            height: inherit;
            align-items: center;
        }
    }
}

.alert-notification-wrapper {
    display: flex;
    flex-direction: column;

    &.top {
        .alert-notification {
            margin: 0px auto 10px auto;
            animation-name: fadeInDown;
        }
    }

    &.bottom {
        bottom: 0;

        .alert-notification {
            margin: 10px auto 0px auto;
            animation-name: fadeInUp;
        }
    }

    .alert-notification {
        padding: 15px $body-margin-x;
        background: theme-color(primary);
        z-index: 99;
        color: $body-text-inverse-color;
        width: 100%;
        border-radius: 0;
        display: flex;
        align-items: center;
        @include ms-respond(font-size, -1);
        box-shadow: $alert-notification-shadow;
        opacity: 0;
        animation-delay: 0.5s;
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        user-select: none;

        i {
            margin-right: 10px;
            font-size: 20px;
            line-height: 1;
        }

        p {
            font-size: inherit;
            font-weight: inherit;
        }

        &.dismissible-alert {
            position: relative;
            padding-right: 40px;

            .alert-close {
                position: absolute;
                top: 10px;
                right: 15px;
                padding: 7px;
                font-size: 18px;
                display: block;
                cursor: pointer;
            }
        }
    }
}