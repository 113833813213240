/* Summernote */

.note-editor {
  &.note-frame {
    border: $border-width $border-style $border-color;
  }

  .panel-heading.note-toolbar {
    z-index: 0;
  }

  .note-btn {
    &.dropdown-toggle {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
  }
}

.note-modal-content {
  display: grid;
}

.note-modal-footer {
  height: auto;
}

.note-popover {
  border: $border-width $border-style $border-color;
}