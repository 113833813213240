/* Bootstrap Tag Input */

.bootstrap-tagsinput {
    background: $input-bg;
    border-color: $input-border-color;
    box-shadow: none;
    min-width: 100%;
    width: 100%;
    max-width: 100%;

    .tag {
        display: inline-block;
        background: theme-color(primary);
        color: $body-text-inverse-color;
        padding: 2px 9px;
        border-radius: $border-radius;
        margin-bottom: 5px;

        &:nth-child(5n + 1) {
            background: theme-color(primary);
        }

        &:nth-child(5n + 2) {
            background: theme-color(success);
        }

        &:nth-child(5n + 3) {
            background: theme-color(info);
        }

        &:nth-child(5n + 4) {
            background: theme-color(warning);
        }

        &:nth-child(5n + 5) {
            background: theme-color(danger);
        }
    }
}