/* Accordions */

@mixin card-header-inverse-bg-variations($color) {
  &:not(.accordion-grouped) {
    a {
      background: rgba($color, 0.2);
      color: $color;

      &:hover {
        background: rgba($color, 0.3);
      }

      &[aria-expanded="true"] {
        background: $color;
        color: $body-text-inverse-color;
      }
    }
  }
}

@each $color,
$value in $theme-colors {
  .card-bg-inverse-#{$color} {
    @include card-header-inverse-bg-variations($value);
  }
}

@mixin card-header-bg-variations($color) {
  box-shadow: $component-box-shadow;

  a {
    background: $color;
    color: $body-text-inverse-color;

    &:hover {
      background: darken($color, 3%);
    }

    &[aria-expanded="true"] {
      background: darken($color, 10%);
    }
  }
}

@each $color,
$value in $theme-colors {
  .card-bg-#{$color} {
    @include card-header-bg-variations($value);
  }
}

.accordion {
  .card {
    .card-header {
      padding: 0;
      @include border-radius(0px);
      background: transparent;
      border-bottom: none;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: block;
        padding: 15px 20px;
        @include font-face($TYPE-1, 500);
        @include ms-respond(font-size, -1);
        letter-spacing: $letter-spacing;
        transition: 0.3s background;
        @include ellipsis;
        position: relative;

        &:after {
          content: "\f140";
          font-family: "Material Design Icons";
          font-size: 15px;
          position: absolute;
          right: 15px;
          top: 50%;
          display: block;
          transition: 0.3s;
          transition-property: transform;
          transform: translateY(-50%);
        }

        &[aria-expanded="true"] {
          &:after {
            transform: translateY(-50%) rotate(-180deg);
          }
        }

        .header-icon {
          margin-right: 10px;
        }
      }
    }

    &:not([class*='card-bg']) {
      .card-header {
        a {
          color: theme-color(dark);

          &:hover {
            background: $component-bg;
          }

          &[aria-expanded="true"] {
            background: $component-bg;
          }
        }
      }
    }

    &:not([class*='card-bg']):not([class*='card-bg-inverse']) {
      .card {
        border: $border-width $border-style $border-color;
      }
    }

    .collapse,
    .collapsing,
    .collapsed {
      .card-body {
        padding: 20px;
      }
    }
  }

  &.accordion-grouped {
    border-radius: $border-radius;
    border: $border-width $border-style $border-color;
  }

  &:not(.accordion-grouped) {
    .card {
      margin-bottom: 10px;
    }
  }

  &:not(.accordion-filled):not(.accordion-color-filled):not(.accordion-grouped) {
    .card {
      border: $border-width $border-style $border-color;
    }
  }

  &.accordion-filled {
    .card {
      .card-header {
        a {
          background: $component-bg;
        }
      }
    }
  }
}