/* Pagination */

.pagination {
    .page-item {
        .page-link {
            background: $card-bg;
            border-color: $border-color;
        }

        &.active {
            .page-link {
                background: rgba(theme-color(primary), 0.3);
                color: theme-color(primary);
            }
        }
    }
}