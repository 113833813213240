/* Wizard */

.step-wzard-container {
    box-shadow: none;
    border: $border-width $border-style $border-color;

    .step-anchor {
        flex-wrap: nowrap;
        overflow-x: auto;

        .nav-item {
            .nav-link {
                @include font-face($TYPE-1, 500);
                @include ms-respond(font-size, -1);
                letter-spacing: $letter-spacing;
                color: $body-text-color;
                white-space: nowrap;
            }
        }

        @include media-breakpoint-down(md) {
            .nav-item {
                .nav-link {
                    padding: 10px;
                    border-bottom: none;

                    small {
                        display: none;
                    }
                }
            }
        }
    }

    .step-container-outer {
        .step-content {
            padding: 30px 20px;
            min-height: 300px;
            background: $card-bg;
        }
    }

    .btn-toolbar {
        padding-bottom: 20px;
        background: $card-bg;

        .sw-btn-next {
            @extend .btn-primary;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.sw-theme-default {
    .step-anchor {
        .nav-item {
            &.done {
                .nav-link {
                    &:after {
                        background: theme-color(success);
                    }
                }
            }

            &.active {
                .nav-link {
                    color: theme-color(primary) !important;

                    &:after {
                        background: theme-color(primary);
                    }
                }
            }
        }
    }

    .btn-toolbar {
        padding-bottom: 10px;
    }
}

.sw-theme-arrows {
    ul.step-anchor {
        background: $template-body-color !important;
        border-bottom: $border-width $border-style $border-color;

        .nav-item {
            .nav-link {
                background: $card-bg;

                &:before {
                    border-left: 30px solid $card-bg;
                }

                &:after {
                    border-left: 30px solid $card-bg;
                }
            }

            &.active {
                .nav-link {
                    border-color: theme-color(primary) !important;
                    background: theme-color(primary) !important;

                    &:after {
                        border-left: 30px solid theme-color(primary) !important;
                    }

                    &:before {
                        border-left: 30px solid $body-accent-color;
                    }
                }
            }

            &.done {
                .nav-link {
                    border-color: lighten(theme-color(primary), 20%) !important;
                    background: lighten(theme-color(primary), 20%) !important;

                    &:after {
                        border-left: 30px solid lighten(theme-color(primary), 20%) !important;
                    }

                    &:before {
                        border-left: 30px solid $body-accent-color;
                    }
                }
            }
        }
    }
}