/* Authentication Pages */

.authentication-theme {
    .signup-link {
        margin: 20px auto 0px auto;
        text-align: center;
        display: flex;
        justify-content: center;

        a {
            display: inline-block;
            margin-left: 10px;
        }
    }

    .auth_footer {
        margin-top: 30px;
    }

    &.auth-style_1 {
        min-height: 100vh;
        width: 100%;
        background: $template-body-color;
        padding: 10% 0 2px 0;

        .logo-section {
            margin-bottom: 50px;
        }

        .grid-body {
            padding: 60px 15px;
            position: relative;

            .form-wrapper {
                position: unset;
            }
        }

        .signup-link {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
        }
    }

    &.auth-style_2 {
        display: flex;
        align-items: stretch;
        justify-content: center;
        min-height: 100vh;

        .inner-wrapper {
            width: 100%;
        }

        .banner-section {
            background: url("http://www.placehold.it/1200x800") no-repeat center;
            background-size: 60%;
            background-color: $card-bg;
            display: flex;
            align-items: flex-end;

            .auth_footer {
                padding: 20px 20px;
            }

            @include media-breakpoint-down(sm) {
                position: absolute;
                top: 0px;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
            }
        }

        .form-section {
            background-color: $card-bg;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .logo-section {
                margin: 0px auto 50px auto;
            }

            form {
                width: 100%;
                max-width: 350px;
                margin: 0 auto;
            }

            @include media-breakpoint-down(sm) {
                background: rgba(255, 255, 255, 0.96);
            }
        }
    }

    &.auth-style_3 {
        background: url("http://www.placehold.it/1200x800") no-repeat center;
        background-size: cover;
        background-color: $card-bg;
        min-height: 100vh;
        height: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;

        .inner-wrapper {
            width: 100%;
        }

        .logo-section {
            margin: 0 auto 50px auto;
        }

        .form-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            form {
                width: 100%;
                max-width: 400px;
                margin: 0 auto;

                @include media-breakpoint-down(sm) {
                    max-width: 300px;
                }
            }
        }
    }
}