/* Calendar */

.calendar-page-wrapper {
    .calendar-container {
        background: $card-bg;
    }
}

.event-list-container {
    background: lighten($template-body-color, 2%);
}

.event-list-wrapper {
    margin-top: 30px;

    &.completed-events {
        margin-top: 50px;

        .event-text {
            text-decoration: line-through;
        }

    }
}

.event-list {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    border-bottom: 1px solid $border-color;

    &:last-child {
        border-bottom: none;
    }

    .status-indicator {
        margin-right: 10px;
    }

    .event-text {
        @include font-face($TYPE-2, 400);
        @include ms-respond(font-size, -1);
        color: $text-gray;
        @include ellipsis;
    }
}